<template>
  <div class="row">
    <input-text
      v-model="filtro.nome"
      class="col-12 col-md-6"
      :label="$t('modulos.requisitos.filtro.nome')"
      em-filtro
    />
    <input-select
      v-model="filtro.tipoRequisito"
      class="col-12 col-md-6"
      :label="$t('modulos.requisitos.filtro.tipo_requisito')"
      :options="opcoes.tiposRequisito"
      em-filtro
    />
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
export default {
  props: {
    filtro: {},
  },
  data() {
    return {
      opcoes: {
        tiposRequisito: helpers.TipoRequisitoEnum,
      },
    };
  },
};
</script>

