<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.requisitos.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.requisitos.titulos.novo')"
      :sem-botao-ok="!buscarPermissao('Requisitos', 'Inserir')"
      :sem-filtro="!buscarPermissao('Requisitos', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <div class="d-flex align-center">
      <botao-padrao
        v-if="buscarPermissao('Requisitos', 'Ordenar')"
        color="secondary"
        outlined
        :disabled="!tabela.selecionados.length"
        @click="movimentarItem('cima')"
      >
        <v-icon> $arrowUp </v-icon>
      </botao-padrao>

      <botao-padrao
        v-if="buscarPermissao('Requisitos', 'Ordenar')"
        color="secondary"
        outlined
        class="mx-2"
        :disabled="!tabela.selecionados.length"
        @click="movimentarItem('baixo')"
      >
        <v-icon> $arrowDown </v-icon>
      </botao-padrao>

      <botao-padrao
        v-if="buscarPermissao('Requisitos', 'Ordenar')"
        color="secondary"
        outlined
        @click="salvarOrdenacao"
      >
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar_ordenacao') }}
      </botao-padrao>

      <botao-padrao
        v-if="podeExcluir && buscarPermissao('Requisitos', 'Deletar')"
        class="my-2 ml-2"
        outlined
        color="danger"
        @click="excluirRegistros"
      >
        <v-icon>$mdiTrashCanOutline</v-icon>
        {{ $t('geral.botoes.excluir') }}
      </botao-padrao>
    </div>
    <tabela-padrao-prime-vue
      v-model="tabela.selecionados"
      filtros-com-colunas
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      mostrar-acoes
      selecionar-colunas
      sem-selecionar-todos
      :mostrar-seletor="buscarPermissao('Requisitos', 'Deletar')"
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      @paginar="listarRegistros"
      @filtrarGlobal="filtrarGlobal"
      @filtroGlobal="filtroGlobal"
      @nova-ordenacao="ordenacaoColuna"
    >
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao
          v-if="buscarPermissao('Requisitos', 'Editar')"
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <dropdown-padrao-item
              text
              color="secondary"
              @click="abrirEditar(slotProps.data)"
            >
              {{ $t('geral.botoes.editar') }}
            </dropdown-padrao-item>
          </div>
        </dropdown-padrao>
      </template>
      <template v-slot:tiposRequisito="{ slotProps }">
        <coluna-multiplos-itens
          v-if="slotProps.data.tiposRequisito"
          :item="MontarViewTags(slotProps.data.tiposRequisito)"
        />
      </template>
    </tabela-padrao-prime-vue>
  </div>
</template>
<script>
import helpers from '@/common/utils/helpers';
import RequisitosService from '@common/services/cadastros/RequisitosService';
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import ColunaMultiplosItens from '@/components/tabelas/ColunaMultiplosItens.vue';
import Filtro from './filtro.vue';
export default {
  components: {
    CabecalhoPagina,
    ColunaMultiplosItens,
    Filtro,
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'sequencia',
            text: this.$t('modulos.requisitos.tabela.sequencia'),
            sortable: true,
          },
          {
            value: 'nome',
            text: this.$t('modulos.requisitos.tabela.nome'),
            sortable: true,
          },
          {
            value: 'tiposRequisito',
            text: this.$t('modulos.requisitos.tabela.tipo_requisito'),
            sortable: false,
          },
          {
            value: 'requisitoSim',
            text: this.$t('modulos.requisitos.tabela.requisito_sim'),
            sortable: true,
          },
          {
            value: 'requisitoNao',
            text: this.$t('modulos.requisitos.tabela.requisito_nao'),
            sortable: true,
          },
          {
            value: 'textoRequisitoObservacao',
            text: this.$t('modulos.requisitos.tabela.texto_entre_requisito_observacao'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      colunaOrdenada: '',
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'Requisitos', 'Listar');

    const filtros = this.buscarStateFiltros();
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
  },
  mounted() {
    this.listarRegistros();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.requisitos.titulos.listagem')
    );
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(this, funcionalidade, funcionalidadeAcao);
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros: function (
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      this.tabela.porPagina = porPagina;
      this.salvarStateFiltros({
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
      });
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
        sort: this.colunaOrdenada,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      RequisitosService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;

          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');

        this.tabela.selecionados.forEach((item) => {
          RequisitosService.excluir(item.id)
            .then(() => {
              this.listarRegistros();
              this.toastSucesso(this.$t(`modulos.requisitos.exclusao_sucesso`));
            })
            .finally(() => {
              this.$store.dispatch('Layout/terminarCarregamento');
              this.tabela.selecionados = [];
            });
        });
      });
    },
    abrirNovo: function () {
      this.$router.push({ name: 'requisitos-novo' });
    },
    abrirEditar: function (item) {
      this.$router.push({
        name: 'requisitos-editar',
        params: { id: item.id },
      });
    },
    buscarStateFiltros() {
      return this.$store.getters['Filtros/filtros'][this.$route.name];
    },
    salvarStateFiltros(dados) {
      this.$store.dispatch('Filtros/adicionarFiltro', {
        rota: this.$route.name,
        dados,
      });
    },
    filtroGlobal(v) {
      this.filtroAplicado.filter = v;
    },
    filtrarGlobal() {
      this.listarRegistros();
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros()
    },
    MontarViewTags(tags) {
      return {
        value: tags.map((v) => {
          return { nome: v };
        }),
      };
    },
        movimentarItem: function (direcao) {
      const incremento = direcao === 'cima' ? -1 : 1;

      let itensSelecionados = [...this.tabela.selecionados];
      let itensSelecionadosComIndex = itensSelecionados.map((item) => {
        return {
          ...item,
          index: this.tabela.dados.indexOf(item),
        };
      });

      itensSelecionadosComIndex.sort((a, b) =>
        incremento === -1 ? a.index - b.index : b.index - a.index
      );

      itensSelecionadosComIndex.forEach((item) => {
        const novaLista = [...this.tabela.dados];
        const novoIndex = item.index + incremento;

        if (novoIndex < 0 || novoIndex >= novaLista.length) {
          return;
        }
        const itemListaAntes = novaLista[item.index + incremento];

        novaLista[item.index + incremento] = novaLista[item.index];
        novaLista[item.index] = itemListaAntes;
        this.tabela.dados = [...novaLista];
      });
    },
    salvarOrdenacao: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');

      RequisitosService.salvarOrdenacao(this.tabela.dados)
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.campo_adicional.salvar_ordenacao_sucesso`)
          );
          this.listarRegistros();
          this.tabela.selecionados = [];
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },

  },
};
</script>
